<template>
  <section class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 m-0 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light"
            >Application :
          </span>
          <span style="font-size: 22px">{{ application.name }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des capacités SI :</h4>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body p-0">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty>
                <div class="text-center text-gray">Table vide</div>
              </template>
              <template v-slot:cell(status)="data">
                <span class="text-primary">{{ data.value }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <b-link
                  :to="{
                    name: 'show-infosys-capacity',
                    params: {
                      id: data.item.id,
                      slug: data.item.slug || 'abcde',
                    },
                  }"
                  >{{ data.value }}</b-link
                >
              </template>

              <template #cell(niveau)="data">
                <span>{{ data.value }}</span>
              </template>

              <template #cell(sousCapacites)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    @click="$emit('showSousCapacite', data.item)"
                    >{{ uniqBy(data.value, "id").length || 0 }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ uniqBy(data.value, "id").length }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(applications)="data">
                <div class="text-center">
                  <b-badge
                    v-if="data.value.length"
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    @click="$emit('showGoals', data.item)"
                    >{{ data.value.length || 0 }}</b-badge
                  >
                  <b-badge
                    v-else
                    style="
                      cursor: pointer;
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                    "
                    pill
                    >{{ data.value.length }}</b-badge
                  >
                </div>
              </template>

              <template #cell(actions)="data">
                <table-actions
                  :actions="['delete']"
                  :editMode="data.item.hasOwnProperty('editMode')"
                  @showItem="
                    $router.push({
                      name: 'show-infosys-capacity',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug || 'abcde',
                      },
                    })
                  "
                  @deleteItem="deleteItem(data.item.id)"
                />
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { uniqBy } from "lodash";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableActions,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { key: "name", label: "Capacité SI", sortable: true },
        {
          key: "sousCapacites",
          label: "Capacité de rattachement",
          sortable: true,
        },
        { key: "niveau", label: "niveau", sortable: true },
        {
          key: "applications",
          label: "Applications",
          sortable: true,
        },
        // {
        //   key: "actions",
        //   label: "",
        //   thStyle: { width: "50px !important", minWidth: "30px !important" },

        //   sortable: false,
        // },
      ],
      items: [],
      loading: true,
    };
  },

  props: {
    application: Object,
  },
  methods: {
    uniqBy,
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("capaciteSi/deleteCapacite", id).then(() => {
            this.$store.dispatch("capaciteSi/fetchAllCapacites");
          });

          Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
  created() {
    this.$store
      .dispatch("application/fetchApplicationCapacites", this.application.id)
      .then(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters("application", ["CAPACITES"]),
    filteredApplications() {
      return this.APPLICATIONS.filter(
        (application) =>
          application.name
            .toLowerCase()
            .indexOf(this.searchQuery.name.toLowerCase()) > -1
      );
    },
    rows() {
      return this.items.length;
    },
  },
  watch: {
    CAPACITES() {
      this.items = this.CAPACITES.capaciteSis;
    },
  },
};
</script>
