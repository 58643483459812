<template>
  <div>
    <b-table
      responsive
      :items="applications"
      :fields="fields"
      show-empty
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(capaciteSis)="data">
        <b-badge
          v-if="data.value.length"
          style="
            cursor: pointer;
            background-color: #adb5bd;
            border-color: #adb5bd;
          "
          pill
          @click="$emit('showCapacites', data.item)"
          >{{ data.value.length || 0 }}</b-badge
        >
        <b-badge
          v-else
          style="
            cursor: pointer;
            background-color: #adb5bd;
            border-color: #adb5bd;
          "
          pill
          >{{ data.value.length }}</b-badge
        >
      </template>
      <template v-slot:cell(ref_sa)="data">
        <span style="text-decoration: underline; color: blue; cursor: pointer"
          >SA{{ data.item.id }}</span
        >
      </template>
      <template v-slot:cell(attachment_capacity)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <b-form-input v-else type="text"></b-form-input>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-application',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }"
          >{{ data.value }}</b-link
        >
      </template>

      <template v-slot:cell(taux)="data">
        <b-progress
          max="100"
          show-progress
          height="15px"
          style="background-color: #cacccd !important"
        >
          <b-progress-bar
            :value="data.value || 0"
            :label="`${data.value || 0}%`"
            :style="`background-color: ${
              data.value > 20 && data.value <= 70
                ? '#f5f245'
                : data.value > 70
                ? '#23d92c'
                : '#f5ba45'
            }`"
          ></b-progress-bar>
        </b-progress>
      </template>

      <template v-slot:cell(po)="data">
        <TableResponsable :responsable="data.value" />
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="isArchitect || isSuper || isAdmin ? ['delete'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @showItem="
            $router.push({
              name: 'show-application',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            })
          "
          @editItem="$emit('editItem', data.item)"
          @cancelItem="$emit('cancelItem', data.item)"
          @confirmItem="$emit('confirmItem', data.item)"
          @deleteItem="deleteItem(data.item.id)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import TableResponsable from "@/components/custom/TableResponsable";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  components: { TableActions, TableResponsable },
  props: {
    applications: Array,
    loading: Boolean,
  },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    fields: [
      { key: "ref_sa", label: "Ref. SA", sortable: true },
      {
        key: "name",
        label: "Application",
        sortable: true,
      },
      {
        key: "status",
        label: "Statut",
        sortable: true,
      },
      {
        key: "capaciteSis",
        label: "Capacité SI",
        sortable: true,
      },
      {
        key: "po",
        label: "PO",
        sortable: true,
      },

      {
        key: "taux",
        label: "Taux de remplissage",
        sortable: true,
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    rows() {
      return this.applications.length;
    },
  },
  methods: {
    addFunction(item) {
      this.application.tableItems[item.itemIndex].function = item.value;
    },

    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("application/deleteApplication", id).then(() => {
            this.$store
              .dispatch("application/fetchAllApplications")
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
  },
};
</script>

<style></style>
