var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card m-0"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"p-2 d-flex flex-column"},[(!_vm.applications.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"166px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e(),_vm._l((_vm.groupBy(_vm.applications, (application) => {
          if (application.capaciteSis.length) {
            return _vm.maxBy(application.capaciteSis, (capacite) => capacite.niveau)['@id'];
          }
          return 'N/A';
        })),function(item,index){return _c('div',{key:index},[(item[0].capaciteSis.length)?_c('p',{staticClass:"mt-3 mb-2 h5"},[_vm._v(" "+_vm._s(_vm.maxBy(item[0].capaciteSis, (capacite) => capacite.niveau).name)+" ")]):_c('p',{staticClass:"mt-3 mb-2 h5"},[_vm._v("N/A")]),_c('div',{staticClass:"text-white row p-2 rounded my-2 py-3",staticStyle:{"background":"#f2edf3"}},[(!item.length)?_c('div',{staticClass:"text-gray d-flex h2 text-center w-100",staticStyle:{"height":"100px"}},[_c('i',{staticClass:"mdi my-auto ml-auto mdi-information-outline my-auto"}),_c('span',{staticClass:"my-auto ml-1 mr-auto"},[_vm._v(" Aucune donnée")])]):_vm._e(),_vm._l((item),function(application,appIndex){return _c('div',{key:appIndex,staticClass:"col-12 col-md-3 mb-2"},[_c('div',{staticClass:"bg-gradient-success rounded h-100 py-2 px-4"},[_c('b-link',{staticClass:"mb-0 text-white",attrs:{"to":{
                  name: 'show-application',
                  params: {
                    id: application.id,
                    slug: application.slug || 'abcde',
                  },
                }}},[_vm._v(_vm._s(application.name))])],1)])})],2)])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }