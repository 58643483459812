<template>
  <div class="row card m-0">
    <div class="col-12">
      <div class="p-2 d-flex flex-column">
        <div
          v-if="!applications.length"
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
        <!-- {{
          groupBy(applications, (application) => {
            if (application.capaciteSis.length) {
              return maxBy(application.capaciteSis, (capacite) => capacite.niveau)["@id"];
            }
            return "N/A";
          })
        }} -->
        <div
          :key="index"
          v-for="(item, index) in groupBy(applications, (application) => {
            if (application.capaciteSis.length) {
              return maxBy(application.capaciteSis, (capacite) => capacite.niveau)['@id'];
            }
            return 'N/A';
          })"
        >
          <p class="mt-3 mb-2 h5" v-if="item[0].capaciteSis.length">
            {{ maxBy(item[0].capaciteSis, (capacite) => capacite.niveau).name }}
          </p>
          <p class="mt-3 mb-2 h5" v-else>N/A</p>

          <div class="text-white row p-2 rounded my-2 py-3" style="background: #f2edf3">
            <div
              v-if="!item.length"
              class="text-gray d-flex h2 text-center w-100"
              style="height: 100px"
            >
              <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
              <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
            </div>
            <div
              class="col-12 col-md-3 mb-2"
              :key="appIndex"
              v-for="(application, appIndex) in item"
            >
              <div class="bg-gradient-success rounded h-100 py-2 px-4">
                <b-link
                  class="mb-0 text-white"
                  :to="{
                    name: 'show-application',
                    params: {
                      id: application.id,
                      slug: application.slug || 'abcde',
                    },
                  }"
                  >{{ application.name }}</b-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy, maxBy } from "lodash";
export default {
  props: {
    data: {
      type: Array,
      default: () => [
        {
          title: "Digital Platform",
          items: [
            ["CRM", "Portals", "Channels", "Ext. Relation", "Ext. API"],
            ["Referetial", "Bi / Reporting", "Big Data / IA"],
          ],
        },
        {
          title: "Core business",
          items: [["Retail Bank", "Insurance", "Leasing", "Financial Market"]],
        },
      ],
    },
    applications: Array,
  },
  methods: {
    groupBy,
    maxBy,
  },
};
</script>

<style></style>
