<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="col-12 p-0">
        <div class="bg-white p-2">
          <b-tabs
            :value="$store.state.tabs.vueApplicativeTab"
            @changed="vueApplicativeTab = $store.state.tabs.vueApplicativeTab"
            @activate-tab="
              (newTabIndex) =>
                $store.dispatch('tabs/changeVueApplicativeTab', newTabIndex)
            "
            class="tab-solid tab-solid-primary mt-3"
          >
            <b-tab
              title="Capacités SI"
              v-if="isAdmin || isSuper || isManager || isArchitect"
            >
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  flex-wrap
                "
              >
                <div class="row col-12 m-0 p-0 align-items-center">
                  <div class="my-auto mr-2">
                    <h4 class="card-title my-auto">Liste des capacités SI</h4>
                  </div>
                  <DropDownCustom
                    :items="['Tableau', 'Graphique']"
                    @changeValue="
                      (value) =>
                        this.$store.dispatch(
                          'views/changeCapaciteSiActiveView',
                          value
                        )
                    "
                    :value="$store.state.views.capaciteSiActiveView"
                  />
                  <div
                    class="
                      d-flex
                      justify-content-end justify-content-center
                      flex-wrap
                      col-sm-12 col-md-auto
                      p-0
                      ml-auto
                      mr-2
                    "
                  >
                    <b-form-group
                      class="ml-2 col-sm-12 col-md-auto p-0 mb-0 my-1"
                      label-for="input1"
                    >
                      <b-form-input
                        v-model="searchQuery.name"
                        type="text"
                        placeholder="Nom"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <CustomAddButton
                    v-if="isArchitect || isSuper || isAdmin"
                    @click="$router.push({ name: 'add-infosys-capacity' })"
                    text="Créer capacité SI"
                  />
                </div>
              </div>
              <hr class="mt-0 bg-secondary" />

              <VisionTable
                v-if="$store.state.views.capaciteSiActiveView == 0"
                @editItem="$router.push({ name: 'edit-infosys-capacity' })"
                @showItem="$router.push({ name: 'show-infosys-capacity' })"
                @showGoals="showGoalsFunction"
                @showSousCapacite="showSousCapaciteFunction"
                class="mt-3"
                :fields="tableFields"
                :items="filteredCapacites"
                :capacites="filteredCapacites"
                :loading="loadingData"
              />

              <CapaciteGraphicView
                :capacites="filteredCapacites"
                class="mt-3"
                v-else
              />
            </b-tab>
            <b-tab
              title="Applications"
              v-if="
                isAdmin ||
                isSuper ||
                isManager ||
                isArchitect ||
                isCollaborator ||
                isPo
              "
            >
              <div class="row mt-0">
                <div class="col-12 d-flex mb-2">
                  <div class="my-auto mr-2">
                    <h4 class="card-title my-auto">Liste des applications</h4>
                  </div>
                  <DropDownCustom
                    :items="['Tableau', 'Graphique']"
                    @changeValue="
                      (value) =>
                        this.$store.dispatch(
                          'views/changeApplicationActiveView',
                          value
                        )
                    "
                    :value="$store.state.views.applicationActiveView"
                    class="mr-2 my-auto"
                  />
                  <div class="col-md-8 ml-auto p-0 d-flex">
                    <div class="pr-1 pl-0 my-auto col-md-3 ml-auto">
                      <b-form-input
                        style="height: 45px"
                        :options="['Libellé SA', 'Lorem', 'Ipsum']"
                        placeholder="Libellé SA"
                        v-model="searchQuery.name"
                      ></b-form-input>
                    </div>
                    <div class="pr-1 pl-0 my-auto col-md-3">
                      <v-select
                        style="height: 45px"
                        v-model="searchQuery.status"
                        :options="['En prod', 'En cours', 'Terminé']"
                        placeholder="Statut"
                      ></v-select>
                    </div>
                    <div class="pr-1 pl-0 my-auto col-md-3">
                      <v-select
                        style="height: 45px"
                        label="text"
                        v-model="searchQuery.po"
                        :reduce="(user) => user.value"
                        :options="responsables"
                        placeholder="PO"
                      ></v-select>
                    </div>

                    <div
                      v-if="isAdmin || isSuper || isArchitect"
                      class="d-flex justify-content-end my-auto p-0 col-md-3"
                    >
                      <CustomAddButton
                        @click="$router.push({ name: 'add-application' })"
                        text="Créer une application"
                        class="w-100 px-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-0 bg-secondary" />

              <div
                class="row"
                v-if="$store.state.views.applicationActiveView == 0"
              >
                <div class="col-12">
                  <div class="card">
                    <div class="card-body p-0">
                      <ApplicationTable
                        @showItem="$router.push({ name: 'show-application' })"
                        @editItem="$router.push({ name: 'edit-application' })"
                        @showProcesses="showProcesses = !showProcesses"
                        :applications="filteredApplications"
                        @showCapacites="showCapacites"
                        :loading="loading"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 p-0 m-0" v-else>
                <graphic-view :applications="filteredApplications" />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
    <div v-if="showCapacitesModule" to="modals-xyz-548">
      <Modal @close="showCapacitesModule = !showCapacitesModule">
        <capacites-list :application="selectedApplication" />
      </Modal>
    </div>

    <!-- CAPACITE SI -->
    <div v-if="showGoals" to="modals-xyz-548">
      <Modal @close="showGoals = !showGoals">
        <goal-list :capacite="selectedCapacite" />
      </Modal>
    </div>

    <div v-if="showSousCapacite" to="modals-xyz-548">
      <Modal @close="showSousCapacite = !showSousCapacite">
        <capacite-list :capacite="selectedCapacite" />
      </Modal>
    </div>
  </div>
</template>

<script>
import DropDownCustom from "@/components/custom/DropDown.vue";
import ApplicationTable from "./components/ApplicationTable.vue";
import Modal from "@/components/custom/Modal.vue";
import GraphicView from "./components/GraphicView.vue";
import { mapGetters } from "vuex";
import CapacitesList from "./components/CapacitesList";
// CAPACITES SI
import VisionTable from "./infoSys-capacity/components/VisionTable.vue";
import GoalList from "./infoSys-capacity/components/GoalList.vue";
import CapaciteList from "./infoSys-capacity/components/CapaciteList.vue";
import CapaciteGraphicView from "./infoSys-capacity/components/GraphicView.vue";
import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  components: {
    DropDownCustom,
    ApplicationTable,
    Modal,
    GraphicView,
    CapacitesList,
    //  CAPACITES SI
    VisionTable,
    GoalList,
    CapaciteGraphicView,
    CapaciteList,
    CustomAddButton,
  },
  data() {
    return {
      selectedView:
        this.$store.state.views.activeView > 1
          ? 0
          : this.$store.state.views.activeView,
      showProcesses: false,
      showCapacitesModule: false,
      loading: true,
      application: {
        tableItems: [
          {
            id: "ce53c675-5a7f-4c1d-81e0-f6f0249292e6",
            ref_sa: "SA1",
            label: "DSP2",
            status: "En construction",
            si_capacity: "SI - Finance",
            po: {
              name: "J.D",
              full_name: "John Doe",
              img: "https://source.unsplash.com/random/150x150",
            },
            tech_lead: {
              name: "S.E",
              full_name: "Steve EWORE",
              img: "https://source.unsplash.com/random/150x150",
            },
            filling_rate: 50,
            actions: ["show", "edit", "delete"],
          },
          {
            id: "ce53c675-5a7f-4c1d-81e0-f6f0249292e6",
            ref_sa: "SA2",
            label: "Contrat IARD",
            status: "En prod",
            si_capacity: "SI - Support",
            po: {
              name: "J.D",
              full_name: "John Doe",
              img: "https://source.unsplash.com/random/150x150",
            },
            tech_lead: {
              name: "S.E",
              full_name: "Steve EWORE",
              img: "https://source.unsplash.com/random/150x150",
            },
            filling_rate: 90,
            actions: ["show", "edit", "delete"],
          },
        ],
      },
      selectedApplication: null,
      // CAPACITES SI
      showGoals: false,
      loadingData: true,
      tableItems: [],
      tableFields: [],
      modalLabel: "",
      showSousCapacite: false,
      showAddVisionModal: false,
      showEditVisionModal: false,
      showVisionModal: false,
      selectedItem: null,
      modeShow:
        this.$store.state.views.activeView > 1
          ? 0
          : this.$store.state.views.activeView,
      // modeShow: ,
      searchQuery: {
        name: "",
        status: "",
        po: "",
      },
      selectedCapacite: null,
      activeTab: null,
    };
  },
  mounted() {
    if (this.$store.state.views.activeView > 1) this.onValueChange(0);
  },
  created() {
    this.$store.dispatch("application/fetchAllApplications").then(() => {
      this.loading = false;
    });
    //CAPACITES SI
    this.$store.dispatch("capaciteSi/fetchAllCapacites").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("users/fetchAllResponsables");
    if (this.$store.state.views.activeView > 1) this.onValueChange(0);
  },

  methods: {
    showCapacites: function (application) {
      (this.selectedApplication = application),
        (this.showCapacitesModule = true);
    },
    // CAPACITE SI
    showItem(item) {
      this.selectedItem = item;
      this.showVisionModal = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEditVisionModal = true;
    },
    onValueChange(value) {
      this.modeShow = value;
      this.selectedView = value;
      this.$store.dispatch("views/changeView", value);
    },
    showGoalsFunction(item) {
      this.showGoals = !this.showGoals;
      this.selectedCapacite = item;
    },
    showSousCapaciteFunction(item) {
      this.showSousCapacite = !this.showSousCapacite;
      this.selectedCapacite = item;
    },
  },
  computed: {
    ...mapGetters("application", ["APPLICATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isManager",
      "isArchitect",
      "isPo",
      "isCollaborator",
    ]),
    responsables() {
      var data = this.RESPONSABLES.map((responsable) => ({
        text: responsable.firstname + " " + responsable.lastname,
        value: responsable.id,
      }));

      return data;
    },
    filteredApplications() {
      return this.APPLICATIONS.filter((application) =>
        this.searchQuery.status
          ? application.status
              .toLowerCase()
              .indexOf(this.searchQuery.status.toLowerCase()) > -1
          : true && this.searchQuery.name
          ? application.name
              .toLowerCase()
              .indexOf(this.searchQuery.name.toLowerCase()) > -1
          : true && this.searchQuery.po
          ? application.po.id === parseInt(this.searchQuery.po)
          : true
      );
    },
    // CAPACITES SI
    ...mapGetters("capaciteSi", ["CAPACITES_SI"]),
    filteredCapacites() {
      return this.CAPACITES_SI.filter(
        (capacite) =>
          capacite.name
            .toLowerCase()
            .indexOf(this.searchQuery.name.toLowerCase()) > -1
      );
    },
  },
};
</script>

<style></style>
